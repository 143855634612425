import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { lang } from "../../lang";

export enum SnackbarKey {
  successfulOp = "successfulOp",
  opAction = "opAction",
}

export enum SnackbarAction {
  CREATE = "Created",
  EDIT = "Edited",
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SuccessSnackbar(props: {
  open: boolean;
  action: SnackbarAction;
}) {
  const [open, setOpen] = React.useState(props.open);

  const msg = `Record${props.action}OK`;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    // remove the flag so the snackbar does not show again
    clearSuccessfulOpFlag();
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {lang(msg)}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export function setSuccessfulOpFlag(action: SnackbarAction) {
  localStorage.setItem(SnackbarKey.successfulOp, "true");
  localStorage.setItem(SnackbarKey.opAction, action);
}

function clearSuccessfulOpFlag() {
  localStorage.removeItem(SnackbarKey.successfulOp);
}
