import React from "react";
import * as Yup from "yup";
import {
  AdminEntities,
  BusinessColumns,
  IRole,
  RoleColumns,
  SheetColumns,
  alwaysVisibleBusinessCols,
  hiddedColumns,
} from "../../types";
import FormikTextField from "./FormikTextField";
import { formatColumnName } from "../../utils/formatters";
import FormikSwitch from "./FormikSwitch";
import CheckboxList from "./CheckboxList";
import CheckboxListEntity from "./CheckboxListEntity";

const businessColsArr = Object.values(BusinessColumns).filter(
  (c) => !alwaysVisibleBusinessCols.includes(c) && !hiddedColumns.includes(c)
);

export const roleInitialValues: Partial<IRole> = {
  name: "",
  onlyOwnRecords: false,
  editOnly: false,
  visibleColumns: [],
  editableColumns: [],
  visibleSheets: [],
};

export const roleValidationSchema = Yup.object({
  [RoleColumns.NAME]: Yup.string().required("Required"),
  [RoleColumns.ONLY_OWN_RECORDS]: Yup.bool().required("Required"),
  [RoleColumns.EDIT_ONLY]: Yup.bool().required("Required"),
  [RoleColumns.VISIBLE_COLUMNS]: Yup.array()
    .of(Yup.string())
    .required("Required"),
  [RoleColumns.EDITABLE_COLUMNS]: Yup.array()
    .of(Yup.string())
    .required("Required"),
  [RoleColumns.VISIBLE_SHEETS]: Yup.array()
    .of(Yup.number())
    .required("Required"),
});

export const roleFields = {
  [RoleColumns.NAME]: (formik: any) => (
    <FormikTextField
      key={"txt-name-1"}
      formik={formik}
      fieldName={RoleColumns.NAME}
      label={formatColumnName(RoleColumns.NAME)}
    />
  ),
  [RoleColumns.ONLY_OWN_RECORDS]: (formik: any) => (
    <FormikSwitch
      key={"sw-or"}
      formik={formik}
      field={RoleColumns.ONLY_OWN_RECORDS}
      label={formatColumnName(RoleColumns.ONLY_OWN_RECORDS)}
    />
  ),
  [RoleColumns.EDIT_ONLY]: (formik: any) => (
    <FormikSwitch
      key={"sw-ed-only"}
      formik={formik}
      field={RoleColumns.EDIT_ONLY}
      label={formatColumnName(RoleColumns.EDIT_ONLY)}
    />
  ),
  [RoleColumns.VISIBLE_COLUMNS]: (formik: any) => {
    return (
      <CheckboxList
        key={"chk-box-ls-vc"}
        formik={formik}
        field={RoleColumns.VISIBLE_COLUMNS}
        label={formatColumnName(RoleColumns.VISIBLE_COLUMNS)}
        options={businessColsArr.filter((c) => c !== BusinessColumns.STATUS_ID)} // Status id is always visible
      />
    );
  },
  [RoleColumns.EDITABLE_COLUMNS]: (formik: any) => {
    return (
      <CheckboxList
        key={"chk-box-ls-ec"}
        formik={formik}
        field={RoleColumns.EDITABLE_COLUMNS}
        label={formatColumnName(RoleColumns.EDITABLE_COLUMNS)}
        options={businessColsArr}
      />
    );
  },
  [RoleColumns.VISIBLE_SHEETS]: (formik: any, entities: AdminEntities) => (
    <CheckboxListEntity
      key={"chkbx-ls-stat-1"}
      formik={formik}
      field={RoleColumns.VISIBLE_SHEETS}
      label={formatColumnName(RoleColumns.VISIBLE_SHEETS)}
      options={entities.sheets}
      idCol={SheetColumns.ID}
    />
  ),
};
