import React from "react";
import * as Yup from "yup";
import { AdminEntities, ISheet, IStatus, StatusColumns } from "../../types";
import FormikTextField from "./FormikTextField";
import { formatColumnName } from "../../utils/formatters";
import CheckboxListEntity from "./CheckboxListEntity";
import FormikDrowdownEntity from "./FormikDropdownEntity";
import { lang } from "../../lang";

export const statusInitialValues: Partial<IStatus> = {
  name: "",
  sheetId: 0,
  derivations: [],
};

export const statusValidationSchema = Yup.object({
  [StatusColumns.NAME]: Yup.string().required("Required"),
  [StatusColumns.SHEET_ID]: Yup.number()
    .min(1, "Sheet is required")
    .required("Required"),
  [StatusColumns.DERIVATIONS]: Yup.array().of(Yup.number()),
});

export const statusFields = {
  [StatusColumns.NAME]: (formik: any) => (
    <FormikTextField
      key={"txt-name-1"}
      formik={formik}
      fieldName={StatusColumns.NAME}
      label={formatColumnName(StatusColumns.NAME)}
    />
  ),
  [StatusColumns.SHEET_ID]: (
    formik: any,
    entities: AdminEntities,
    classes: any
  ) => (
    <FormikDrowdownEntity
      key={"txt-sheet-1"}
      label={lang("Sheet")}
      formik={formik}
      field={StatusColumns.SHEET_ID}
      options={entities.sheets.map((s: ISheet) => ({
        value: s.sheetId,
        label: s.name.toUpperCase(),
      }))}
      classes={classes}
    />
  ),
  [StatusColumns.DERIVATIONS]: (formik: any, entities: AdminEntities) => {
    return (
      <CheckboxListEntity
        key={"chk-box-ls-st"}
        formik={formik}
        field={StatusColumns.DERIVATIONS}
        label={formatColumnName(StatusColumns.DERIVATIONS)}
        options={entities.statuses}
        idCol={StatusColumns.ID}
      />
    );
  },
};
