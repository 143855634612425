import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  ADMIN_ROLE_ID,
  AdminSection,
  GenericMap,
  IRole,
  ISheet,
  IUser,
  Path,
} from "../types";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { firstLetterToUpperCase, parseNameToPath } from "../utils/formatters";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import TitlebarImageList, {
  ListItem,
  iconStyle,
} from "../components/menu/TilebarImageList";
import { AdminSectionIcons } from "../components/icons";
import {
  handleAdminButtonClick,
  handleAdminSectionButtonClick,
  handleGoToHomeClick,
  handleSheetButtonClick,
} from "../components/nav/utils";
import useSheetWSEvents from "../hooks/websocket/sheetEvents";
import { getStoredItem } from "../state/localstorage";
import * as _ from "lodash";
import { lang } from "../lang";
import useRoleWSEvents from "../hooks/websocket/roleEvents";
import BasePage from "../components/page/BasePage";
import { logout } from "../services/api/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "90vh",
  },
  root: {
    width: "100%",
    maxWidth: "900px",
    margin: "1rem auto",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
}));

interface Props {
  isAdmin: boolean;
}

const MenuPage = (props: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  // get user role info "visibleSheets" from LocalStorage
  const [sheetsMap, setSheetsMap] = useState<GenericMap<ISheet>>(
    getStoredItem(AdminSection.Sheets) as GenericMap<ISheet>
  );
  const [role, setRole] = useState<IRole>(getStoredItem("role") as IRole);

  const user = getStoredItem("user") as IUser;
  const userName = user && user.name && user.name.split(" ")[0];

  const [socket] = useSheetWSEvents({ setSheetsMap });
  useRoleWSEvents({ setRole, setSheetsMap });

  if (_.isEmpty(role) || _.isEmpty(sheetsMap) || _.isEmpty(user)) {
    logout(socket);
    return <Navigate to={Path.LOGIN} />;
  }

  let menuItems: ListItem[];

  if (props.isAdmin) {
    menuItems = Object.values(AdminSection).map((s: AdminSection) => {
      const sectionName = firstLetterToUpperCase(s);
      return {
        title: sectionName,
        img: AdminSectionIcons[s](iconStyle),
        onClick: () =>
          handleAdminSectionButtonClick(navigate, `/${s}`, sectionName),
      };
    });

    menuItems.push({
      title: "Home",
      img: <HomeIcon sx={iconStyle} />,
      onClick: () => handleGoToHomeClick(navigate, sheetsMap),
      // cols: 2,
    });
  } else {
    menuItems = Object.values(sheetsMap).map((s: ISheet) => {
      const pathName = parseNameToPath(s.name);
      return {
        title: s.name,
        onClick: () =>
          handleSheetButtonClick(
            navigate,
            s.sheetId,
            pathName,
            s.name,
            sheetsMap,
            role
          ),
      } as ListItem;
    });

    if (role.roleId === ADMIN_ROLE_ID) {
      menuItems.unshift({
        title: "AdminPanel",
        img: <AdminPanelSettingsIcon sx={iconStyle} />,
        onClick: () => handleAdminButtonClick(navigate, role, sheetsMap),
        cols: 2,
      });
    }
  }

  return (
    <BasePage
      containerClassName={classes.container}
      role={role}
      sheetsMap={sheetsMap}
      addBtn={false}
    >
      <Paper className={classes.root}>
        <TitlebarImageList
          items={menuItems}
          title={`${lang("Welcome")} ${userName || ""}!`}
        />
      </Paper>
    </BasePage>
  );
};

export default MenuPage;
