import { ApiPath } from "./client";
import ApiHandler from "./handler";
import { IPublicUser, IUser } from "../../types";

// Define the User API class
class UserApi extends ApiHandler<IUser> {
  constructor() {
    super(ApiPath.USERS, "userId");
  }

  // Define the new function to get users by their role ID
  public async getByRoleId(roleId: number): Promise<IPublicUser[]> {
    return this.request<IPublicUser[]>(
      `${this.basePath}/role/${roleId}`,
      "get"
    );
  }
}

export default new UserApi();
