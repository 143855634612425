import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  }
}));

const ButtonContainer = ({ children }: any) => {
  const classes = useStyles();

  return <div className={classes.buttonCont}>{children}</div>;
};

ButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonContainer;
