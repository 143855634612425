import React from "react";
import { TextField } from "@material-ui/core";
import { FormikValues } from "formik";

const FormikBusinessTextField = (props: {
  formik: FormikValues;
  fieldName: string;
  label: string;
  type: string;
  rows?: number;
}) => {
  return (
    <TextField
      id={props.fieldName}
      name={props.fieldName}
      label={props.label}
      type={props.type}
      variant="outlined"
      margin="normal"
      key={`text-${props.fieldName}-1`}
      minRows={props.rows || 1}
      fullWidth
      value={props.formik.values[props.fieldName]}
      onChange={props.formik.handleChange}
      onBlur={props.formik.handleBlur}
      error={
        props.formik.touched[props.fieldName] &&
        Boolean(props.formik.errors[props.fieldName])
      }
      helperText={
        props.formik.touched[props.fieldName] &&
        props.formik.errors[props.fieldName]
      }
    />
  );
};

export default FormikBusinessTextField;
