import { useEffect, useState } from "react";
import prodApi from "../services/api/productos";
import { IProducto } from "../types";

const useProductos = (dontFetch?: boolean) => {
  const [productos, setProductos] = useState<IProducto[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const ps = await prodApi.getAll();
        setProductos(ps);
      } catch (error: any) {
        console.error(error);
        setError(error.response?.data?.message || "An error occurred");
      }
    };

    !dontFetch && fetchProductos();
    setLoading(false);
  }, []);

  return { productos, loadingProductos: loading, prodErr: error };
};

export default useProductos;
