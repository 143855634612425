import { useState, useEffect } from "react";

// store user preferences to have better ux
// pagination may be different on different sheets
// so need to consider the sheet too
function useTablePaginationStorage(
  defaultPage: number,
  defaultRowsPerPage: number,
  pageName?: string
) {
  const paginationStorageKey = pageName
    ? `paginationPage_${pageName}`
    : "paginationPage";
  const rowsPerPageStorageKey = `paginationRowsPerPage`;

  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    const storedPage = localStorage.getItem(paginationStorageKey);
    const storedRowsPerPage = localStorage.getItem(rowsPerPageStorageKey);

    if (storedPage !== null) {
      setPage(parseInt(storedPage, 10));
    }
    if (storedRowsPerPage !== null) {
      setRowsPerPage(parseInt(storedRowsPerPage, 10));
    }
  }, [pageName]); // Watch for changes in tableId

  useEffect(() => {
    localStorage.setItem(paginationStorageKey, `${page}`);
    localStorage.setItem(rowsPerPageStorageKey, `${rowsPerPage}`);
  }, [page, rowsPerPage, pageName]);

  return { page, setPage, rowsPerPage, setRowsPerPage };
}

export default useTablePaginationStorage;
