import React from "react";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDelete from "./ConfirmDelete";

interface Props {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteButton = (props: Props) => {
  return (
    <>
      <IconButton
        key={"tc-delete-btn"}
        aria-label="delete"
        color="secondary"
        onClick={props.onClick}
      >
        <DeleteIcon />
      </IconButton>
      <ConfirmDelete
        open={props.open}
        onClose={props.onClose}
        onConfirm={props.onConfirm}
      />
    </>
  );
};

export default DeleteButton;
