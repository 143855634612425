import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Paper, Typography } from "@material-ui/core";
import {
  adminFormFields,
  adminInitialVals,
  adminValSchema,
  formStyles,
} from "../../components/form";
import {
  ADMIN_ROLE,
  AdminEntity,
  AdminSection,
  AdminSectionCols,
  GenericMap,
  IRole,
  ISheet,
  IStatus,
  Path,
  apiServices,
} from "../../types";
import { getStoredItem, refreshStoredState } from "../../state/localstorage";
import { formatColumnName } from "../../utils/formatters";
import GoBackButton from "../../components/btn/GoBackButton";
import SaveButton from "../../components/btn/SaveButton";
import ButtonContainer from "../../components/btn/ButtonContainer";
import {
  SnackbarAction,
  setSuccessfulOpFlag,
} from "../../components/form/SuccessSnackbar";
import useRoles from "../../hooks/roles";
import { lang } from "../../lang";
import * as _ from "lodash";
import BasePage from "../../components/page/BasePage";

const useStyles = formStyles;

const AdminCreatePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { roles, error: rolesApiError } = useRoles();
  const { section } = useParams();

  if (rolesApiError) {
    setError(rolesApiError);
  }

  const apiService = apiServices[section as AdminSection];
  // get the fields from the current entity
  const fields = Object.values(AdminSectionCols[section as AdminSection]);

  const initValues = adminInitialVals[section as AdminSection];
  const validationSchema = adminValSchema[section as AdminSection];
  const formFields = adminFormFields[section as AdminSection];

  // get status and roles for the forms
  const sheetsMap = getStoredItem(AdminSection.Sheets) as GenericMap<ISheet>;
  const statusesMap = getStoredItem(AdminSection.Status) as GenericMap<IStatus>;
  // this is set on login when user is admin
  // if not present, redirect to main menu
  if (_.isEmpty(sheetsMap) || _.isEmpty(statusesMap)) {
    navigate(Path.ROOT);
  }

  const sheets = Object.values(sheetsMap);
  const statuses = Object.values(statusesMap);

  const formik = useFormik({
    initialValues: initValues as AdminEntity,
    validationSchema,
    onSubmit: (values: AdminEntity) => {
      handleSubmit(values as any);
    },
  });

  const handleSubmit = async (values: AdminEntity) => {
    try {
      await apiService.create(values as any);
      // after create a new role/status,
      // refresh the local storage
      await refreshStoredState(section as AdminSection);
      setSuccessfulOpFlag(SnackbarAction.CREATE);
      navigate(`/${section as AdminSection}`);
    } catch (error: any) {
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    }
  };

  return (
    <BasePage role={ADMIN_ROLE as IRole} sheetsMap={sheetsMap} addBtn={false}>
      <Paper className={classes.root}>
        <Typography variant="h6" id="formTitle" component="div">
          {`${lang("CreateRecord")} ${formatColumnName(section as string)}`}
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          {fields.map((c: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const fieldComponent = formFields[c];
            if (!fieldComponent) {
              return;
            }
            return fieldComponent(formik, { roles, sheets, statuses }, classes);
          })}
          <ButtonContainer>
            <SaveButton key={"create-adm-btn-1"} />
            <GoBackButton key={"create-adm-btn-2"} />
          </ButtonContainer>
        </form>
      </Paper>
    </BasePage>
  );
};

export default AdminCreatePage;
