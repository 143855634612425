import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { IOption } from "../../types";

const FormikDrowdownEntity = (props: {
  formik: any;
  field: string;
  label: string;
  options: IOption[];
  classes: any;
}) => {
  return (
    <FormControl
      key={"fc-1"}
      variant="outlined"
      className={props.classes.formControl}
      error={
        props.formik.touched[props.field] &&
        Boolean(props.formik.errors[props.field])
      }
    >
      <InputLabel id="status-label">{props.label}</InputLabel>
      <Select
        id={props.field}
        name={props.field}
        labelId={`${props.label}-label`}
        value={props.formik.values[props.field] || ""}
        onChange={props.formik.handleChange}
        onBlur={props.formik.handleBlur}
        label={props.label}
        fullWidth
      >
        {props.options.map((o) => (
          <MenuItem key={`mi-${o.value}`} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {props.formik.touched[props.field] &&
        props.formik.errors[props.field] && (
          <FormHelperText>{props.formik.errors[props.field]}</FormHelperText>
        )}
    </FormControl>
  );
};

export default FormikDrowdownEntity;
