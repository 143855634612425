import { Socket } from "socket.io-client";
import { useEffect, useState } from "react";
import { wsLogin, wsLogout } from "../../services/ws/auth";

interface ReconnectionParams {
  reconnectAttempts: number;
  maxReconnectAttempts: number;
  reconnectTimeout: ReturnType<typeof setTimeout> | null;
  reconnectInterval: number;
}

interface ConnectSocketResult {
  socket: Socket | null;
  reconnectTimeout: ReturnType<typeof setTimeout> | null;
}

const useWebSocket = (): [Socket | null, () => void] => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const { socket, reconnectTimeout } = connectWebSocket();
    if (!socket) return;

    return () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      // clean up: disconnect when component unmounts
      socket && socket.disconnect();
      setSocket(null);
    };
  }, []);

  const connectWebSocket = (): ConnectSocketResult => {
    let currentSocket: Socket | null = null;
    let reconnectTimeout: ReturnType<typeof setTimeout> | null = null;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    const reconnectInterval = 3000; // 3 seconds

    const token = localStorage.getItem("token");
    if (token) {
      currentSocket = wsLogin(token);
      setSocket(currentSocket);

      currentSocket.on("connect", () => {
        console.log("Connected to WS server");
        reconnectAttempts = 0;
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout);
        }
      });

      currentSocket.on("disconnect", () => {
        console.log("Disconnected from WS server");
      });

      currentSocket.on("error", (error) => {
        console.error("WebSocket error:", error);
        reconnectTimeout = handleReconnect(currentSocket, {
          reconnectAttempts,
          reconnectInterval,
          reconnectTimeout,
          maxReconnectAttempts,
        });
      });
    }
    return { socket: currentSocket, reconnectTimeout };
  };

  const handleReconnect = (
    socket: Socket | null,
    params: ReconnectionParams
  ): ReturnType<typeof setTimeout> | null => {
    const { maxReconnectAttempts, reconnectInterval } = params;
    let { reconnectAttempts, reconnectTimeout } = params;
    if (reconnectAttempts < maxReconnectAttempts) {
      reconnectTimeout = setTimeout(() => {
        console.log("Reconnecting...");
        reconnectAttempts++;
        connectWebSocket();
      }, reconnectInterval);
    } else {
      console.error("WebSocket connection failed after multiple attempts");
      socket && socket.disconnect();
      setSocket(null);
    }
    return reconnectTimeout;
  };

  const disconnectWebSocket = () => {
    // Call wsLogout to perform the disconnection logic
    wsLogout();
    // Set socket to null to indicate the disconnection
    setSocket(null);
  };

  return [socket, disconnectWebSocket];
};

export default useWebSocket;
