import React from "react";
import * as Yup from "yup";
import { IProducto, ProductoColumns } from "../../types";
import FormikTextField from "./FormikTextField";
import { formatColumnName } from "../../utils/formatters";

export const productoInitialValues: Partial<IProducto> = {
  nombre: "",
};

export const productoValidationSchema = Yup.object({
  [ProductoColumns.NOMBRE]: Yup.string().required("Required"),
});

export const productoFields = {
  [ProductoColumns.NOMBRE]: (formik: any) => (
    <FormikTextField
      key={"txt-name-1"}
      formik={formik}
      fieldName={ProductoColumns.NOMBRE}
      label={formatColumnName(ProductoColumns.NOMBRE)}
    />
  ),
};
