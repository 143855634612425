import { useEffect } from "react";
import { Events, IUser } from "../../types";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { Socket } from "socket.io-client";
import { getStoredItem } from "../../state/localstorage";

interface Props {
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
}

// these events are pushed only to the role's room or user-specific room.
// Checks if user is same userId than event and update if corresponds
const useUserWSEvents = ({ setUser }: Props): [Socket | null] => {
  const { socket } = useWebSocketContext();
  useEffect(() => {
    const eventHandlers: Record<string, (...args: any[]) => void> = {};

    if (socket) {
      // this will be pushed to admin room only
      eventHandlers[Events.UserDeactivated] = async (data: IUser) => {
        const user = getStoredItem("user") as IUser;
        if (user.userId !== data.userId) {
          return;
        }
        // user deactivated
        // Refresh the local storage item
        user.active = false;
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
      };

      // Register the event handlers with the socket
      Object.keys(eventHandlers).forEach((eventName) => {
        socket.on(eventName, eventHandlers[eventName]);
      });
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (socket) {
        // Remove all event listeners from the socket
        Object.keys(eventHandlers).forEach((eventName) => {
          socket.off(eventName, eventHandlers[eventName]);
        });
      }
    };
  }, [socket]);
  return [socket];
};

export default useUserWSEvents;
