import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  rndButton: {
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    marginLeft: "1rem",
    marginRight: "1rem",    
  }
}));

const GoBackButton = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
      <Button
        variant="contained"
        color="secondary"
        className={classes.rndButton}
        onClick={handleGoBack}
      >
        <ArrowBack />
      </Button>
  );
};

export default GoBackButton;
