import React, { ReactNode, createContext, useContext } from "react";
import useWebSocket from "../hooks/websocket/ws";
import { Socket } from "socket.io-client";

interface WebSocketContextType {
  socket: Socket | null;
  disconnectWebSocket: () => void;
}

interface WebSocketProviderProps {
  children: ReactNode;
}

const initialWebSocketContext: WebSocketContextType = {
  socket: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnectWebSocket: () => {},
};

const WebSocketContext = createContext<WebSocketContextType>(
  initialWebSocketContext
);

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const [socket, disconnectWebSocket] = useWebSocket();

  const contextValue = {
    socket,
    disconnectWebSocket,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
