import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { Alert } from "@mui/material";
import { lang } from "../../lang";

const NoRecordsRow = () => {
  return (
    <TableRow>
      <TableCell colSpan={1000}>
        <Alert
          style={{
            fontWeight: 600,
          }}
          key={"typ-no-records-err"}
          severity="info"
        >
          {lang("NoRecordsFound")}
        </Alert>
      </TableCell>
    </TableRow>
  );
};

export default NoRecordsRow;
