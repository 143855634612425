import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(() => ({
  rndButton: {
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const SaveButton = () => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className={classes.rndButton}
    >
      <SaveIcon />
    </Button>
  );
};

export default SaveButton;
