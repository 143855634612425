import React, { ReactNode } from "react";
import PrimaryAppBar from "../../components/nav/AppBar";
import Footer from "../../components/footer/Footer";
import { GenericMap, IRole, ISheet } from "../../types";

interface BasePageProps {
  role: IRole;
  sheetsMap: GenericMap<ISheet>;
  children: ReactNode;
  addBtn: boolean;
  containerClassName?: string;
  section?: string;
}

const BasePage: React.FC<BasePageProps> = ({
  role,
  sheetsMap,
  children,
  addBtn,
  containerClassName,
  section,
}) => {
  return (
    <>
      <div className={containerClassName}>
        <PrimaryAppBar
          role={role}
          sheets={sheetsMap}
          addBtn={addBtn}
          section={section}
        />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default BasePage;
