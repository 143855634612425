import React from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { AdminSection, Path } from "../../types";
import { formatColumnName } from "../../utils/formatters";
import { AdminSectionIcons } from "../icons";
import { handleAdminSectionButtonClick } from "./utils";
import { lang } from "../../lang";

export default function AdminDrawerSection() {
  const navigate = useNavigate();
  const adminMenu = lang("AdminPanel");
  return (
    <>
      <Divider style={{ borderWidth: "3px" }} />
      <List>
        <ListItem
          key="admin-menu-item-first"
          onClick={() =>
            handleAdminSectionButtonClick(navigate, Path.ADMIN, adminMenu)
          }
        >
          <ListItemButton>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary={adminMenu} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {Object.values(AdminSection).map((s: AdminSection, i: number) => {
          const sectionName = formatColumnName(s);
          return (
            <ListItem
              key={`admin-menu-item-${i}`}
              onClick={() =>
                handleAdminSectionButtonClick(navigate, `/${s}`, sectionName)
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{AdminSectionIcons[s]()}</ListItemIcon>
                <ListItemText primary={sectionName} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
