import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@material-ui/core";
import { lang } from "../../lang";

interface Props {
  resetVisibility: () => void;
}

// TODO add this button in the table tool bar
const ShowHidenColumnsButton = (props: Props) => (
  <Tooltip title={lang("ShowColumns")}>
    <IconButton onClick={props.resetVisibility}>
      <VisibilityIcon  />
    </IconButton>
  </Tooltip>
);

export default ShowHidenColumnsButton;
