import React from "react";
import { Skeleton } from "@mui/material";

export default function FormSkeleton(props: { fieldCount: number }) {
  const skeletonFields = Array.from({ length: props.fieldCount }, (_, index) => (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={40}
      style={{ margin: "16px" }}
      key={`ld-fm-${index.toString()}`}
    />
  ));

  return <>{skeletonFields}</>;
}
