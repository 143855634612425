import React from "react";
import { FormControlLabel } from "@material-ui/core";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { formStyles } from ".";
import { formatColumnName } from "../../utils/formatters";

const CheckboxList = (props: {
  formik: any;
  label: string;
  field: string;
  options: string[];
}) => {
  const useStyles = formStyles;
  const classes = useStyles();

  return (
    <FormControl
      key={"fc-status-1"}
      variant="outlined"
      className={classes.formControl}
      error={
        props.formik.touched[props.field] &&
        Boolean(props.formik.errors[props.field])
      }
    >
      <Typography
        variant="h6"
        gutterBottom
        color="textSecondary"
        style={{ marginTop: "2rem" }}
      >
        {props.label}
      </Typography>
      <FormGroup>
        {props.options.map((opt: string, i: number) => (
          <FormControlLabel
            key={`chk-${opt}-${i}`}
            control={
              <Checkbox
                key={`chk-box-${opt}-${i}`}
                checked={props.formik.values[props.field].includes(opt)}
                onChange={(event) => {
                  const checked = event.target.checked;
                  props.formik.setFieldValue(
                    props.field,
                    checked
                      ? [...props.formik.values[props.field], opt]
                      : props.formik.values[props.field].filter(
                          (id: string) => id !== opt
                        )
                  );
                }}
                value={opt}
              />
            }
            label={formatColumnName(opt)}
          />
        ))}
      </FormGroup>
      {props.formik.touched[props.field] &&
        props.formik.errors[props.field] && (
          <FormHelperText>{props.formik.errors[props.field]}</FormHelperText>
        )}
    </FormControl>
  );
};

export default CheckboxList;
