import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  ThemeProvider,
  Container,
  Typography,
} from "@material-ui/core";
import Copyright from "../components/footer/Copyright";
import {
  loginWithGoogle,
  loginWithPassword,
  logout,
} from "../services/api/auth";
import {
  googleLogout,
  GoogleLogin,
  CredentialResponse,
} from "@react-oauth/google";
import { AdminSection, IRole, ISheet, IStatus, Path } from "../types";
import theme from "../theme";
import { storeMap } from "../state/localstorage";

export default function SignIn() {
  const [error, setError] = React.useState("");

  const handleGoogleSuccess = async (response: CredentialResponse) => {
    try {
      if (!response.credential) {
        googleLogout();
        return;
      }
      const role = await loginWithGoogle(response.credential);

      await handleSuccessfulLogin(role);
    } catch (error: any) {
      console.error("Google login error:", error);
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const role = await loginWithPassword(
        data.get("email") as string,
        data.get("password") as string
      );
      await handleSuccessfulLogin(role);
    } catch (error: any) {
      console.log(error);
      logout();
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  const handleSuccessfulLogin = async (role: IRole) => {
    // handle successful login
    // create a sheets map to be more efficient when using this data
    // keep only the visible statuses for the user role
    const sheetsMap = await storeMap<ISheet>(AdminSection.Sheets, role);

    // having the statuses map is also handy
    await storeMap<IStatus>(AdminSection.Status, role);

    navigate(Path.ROOT, { state: { sheetsMap } });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "7rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/static/TBG-logo.png"
            alt="Logo"
            style={{ marginBottom: "1.5rem", maxWidth: "200px" }}
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            style={{ marginTop: "1rem" }}
          >
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: "3rem", marginBottom: "2rem" }}
              color="primary"
            >
              Sign In
            </Button> */}

            <Grid container justifyContent="center" alignItems="center">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => {
                  setError("Login failed");
                }}
              />
            </Grid>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright style={{ marginTop: "1rem" }} />
      </Container>
    </ThemeProvider>
  );
}
