import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@mui/material";

function LoadingRow() {
  return (
    <TableRow>
      <TableCell colSpan={2000}>
        <Skeleton variant="rectangular" width={"100%"} height={40} />
      </TableCell>
    </TableRow>
  );
}

export default function TableLoadingSkeleton(props: { rows: number }) {
  const skeletonRows = Array.from({ length: props.rows }, (_, index) => (
    <LoadingRow key={`ld-rw-${index.toString()}`} />
  ));

  return <>{skeletonRows}</>;
}
