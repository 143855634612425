import { NavigateFunction } from "react-router-dom";
import { IRole, Path, SheetsMap } from "../../types";

export const handleGoToHomeClick = (
  navigate: NavigateFunction,
  sheetsMap: SheetsMap
) => {
  navigate(Path.ROOT, {
    state: { sheetsMap },
  });
};

export const handleAdminButtonClick = (
  navigate: NavigateFunction,
  role: IRole,
  sheetsMap: SheetsMap
) => {
  navigate(Path.ADMIN, {
    state: {
      role,
      sheetsMap,
    },
  });
};

export const handleAdminSectionButtonClick = (
  navigate: NavigateFunction,
  tablePath: string,
  tableName: string
) => {
  navigate(tablePath, {
    state: {
      tablePath,
      tableName,
    },
  });
};

export const handleSheetButtonClick = (
  navigate: NavigateFunction,
  sheetId: number,
  tablePath: string,
  tableName: string,
  sheetsMap: SheetsMap,
  role: IRole
) => {
  navigate(`${Path.LIST}/${tablePath}`, {
    state: {
      sheetId,
      sheet: sheetsMap[sheetId],
      tableName,
      tablePath,
      role,
      visibleSheets: sheetsMap,
    },
  });
};
