import React from "react";
import { Alert } from "@mui/material";

const ErrorMsg = (props: { error: string }) => {
  return (
    <Alert
      style={{
        fontWeight: 600,
      }}
      key={"typ-err"}
      severity="error"
    >
      {props.error}
    </Alert>
  );
};

export default ErrorMsg;
