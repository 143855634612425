import { useEffect, useState } from "react";
import usersApi from "../services/api/users";
import { IPublicUser, IRole } from "../types";

const useUsersByRole = (roleNames: string[], roles: IRole[]) => {
  const [usersByRole, setUsers] = useState<{ [key: string]: IPublicUser[] }>(
    {}
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const usersByRole: { [key: string]: IPublicUser[] } = {};
      try {
        for (const name of roleNames) {
          for (const role of roles) {
            if (role.name.toLowerCase() !== name.toLowerCase()) {
              continue;
            }
            const res = await usersApi.getByRoleId(role.roleId);
            if (!res.length) {
              continue;
            }
            usersByRole[name] = res;
          }
        }
        setUsers(usersByRole);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (roleNames.length > 0 && roles.length > 0 && loading) {
      fetchData();
      setLoading(false);
    }
  }, [roleNames, roles]);

  // No users to fetch, return empty struct and loading = false
  if (!roleNames.length) {
    return { usersByRole, loadingUsers: false };
  }

  if (Object.keys(usersByRole).length === 0) {
    return { usersByRole, loadingUsers: true };
  }

  return { usersByRole, loadingUsers: loading };
};

export default useUsersByRole;
