import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, logout } from "../../services/api/auth";
import { IRole, IUser, Path } from "../../types";
import { getStoredItem } from "../../state/localstorage";
import useUserWSEvents from "../../hooks/websocket/userEvents";

interface AuthRouteProps {
  children: JSX.Element;
  roleId?: number; // Role ID for role-based authentication
}

// This component checks if the user is authenticated before rendering the component passed to it
// If the user is not authenticated, it redirects to the login page
const AuthRoute = ({ children, roleId }: AuthRouteProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loggedIn, setLoggedIn] = useState(true);
  const [user, setUser] = useState<IUser>(getStoredItem("user") as IUser);
  const [error, setError] = useState("");
  const [socket] = useUserWSEvents({ setUser });

  useEffect(() => {
    let isMounted = true; // Create a flag to track if the component is still mounted
    async function checkToken() {
      try {
        const isLoggedIn = await isAuthenticated();
        // Check if the component is still mounted before updating the state
        if (isMounted) {
          setLoggedIn(isLoggedIn);
        }
      } catch (error: any) {
        if (isMounted) {
          setError(error.response?.data?.message || "An error occurred");
        }
      }
    }
    checkToken();
    // Cleanup function: This will run when the component is unmounted or before the effect runs again
    return () => {
      isMounted = false; // Set the flag to false to indicate the component is unmounted
    };
  }, []);

  useEffect(() => {
    if (error || !loggedIn || !user || !user.active) {
      logout(socket);
      navigate(Path.LOGIN);
      return;
    }

    // Role-based authentication
    if (roleId) {
      let userRole: IRole | undefined;
      if (location.state) {
        userRole = location.state.role as IRole;
      }
      if (!userRole) {
        userRole = getStoredItem("role") as IRole;
      }

      if (!userRole || userRole.roleId !== roleId) {
        navigate(Path.ROOT);
      }
    }
  }, [loggedIn, user, error]);

  return children;
};

export default AuthRoute;
