import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Order, SortingField } from "../../types";
import {
  Box,
  TableSortLabel,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { lang } from "../../lang";
import HideColumnButton from "../btn/HideColumnButton";
import { visuallyHidden } from "@mui/utils";
import { listStyle } from "../../styles";

const useStyles = makeStyles(() => listStyle);

interface ColumnMenuProps {
  columnName: string;
  orderBy?: SortingField;
  order?: Order;
  toggleVisibility?: (column: string) => void;
  toggleSort?: (field: SortingField) => void;
  onFilterOpen?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ColumnMenu = ({
  columnName,
  orderBy,
  order,
  toggleVisibility,
  toggleSort,
  onFilterOpen,
}: ColumnMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "inline" }}>
      <Tooltip title={lang("ColumnOptions")}>
        <IconButton
          aria-label="more"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ height: "40px", width: "40px" }}
        >
          <MoreVertIcon style={{ height: "17px" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: "20ch",
          },
        }}
      >
        {toggleSort && (
          <MenuItem
            key="sort-opt"
            onClick={() => toggleSort(columnName as unknown as SortingField)}
          >
            <Typography>
              <TableSortLabel
                active={true}
                direction={orderBy === columnName ? order : "asc"}
                style={{ padding: "6px" }}
              >
                {orderBy === columnName ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
              {`${lang("SortOrder")} ${order}`}
            </Typography>
          </MenuItem>
        )}
        {onFilterOpen && (
          <MenuItem
            key="fltr-opt"
            onClick={(e) => {
              onFilterOpen({
                currentTarget: anchorEl,
              } as React.MouseEvent<HTMLElement>);
              handleClose(e);
            }}
          >
            <Typography>
              <IconButton>
                <FilterListIcon sx={{ fontSize: "1.2rem" }} />
              </IconButton>
              {lang("Filter")}
            </Typography>
          </MenuItem>
        )}
        {toggleVisibility && (
          <MenuItem
            key="vis-opt"
            onClick={(e) => {
              toggleVisibility(columnName);
              handleClose(e);
            }}
          >
            <HideColumnButton classes={classes} />
            <Typography>{lang("HideColumn")}</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ColumnMenu;
