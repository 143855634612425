import { useState, useEffect } from "react";
import rolesApi from "../services/api/roles";
import { AdminSection, IRole } from "../types";
import { getStoredItem } from "../state/localstorage";

function useRoles() {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = getStoredItem(AdminSection.Roles) as IRole[] | undefined;
      if (roles) {
        setRoles(roles);
        return;
      }

      try {
        const rs = await rolesApi.getAll();
        setRoles(rs);
        localStorage.setItem(AdminSection.Roles, JSON.stringify(rs));
      } catch (error: any) {
        console.error(error);
        setError(error.response?.data?.message || "An error occurred");
      }
    };

    fetchRoles();
  }, []);

  return { roles, error };
}

export default useRoles;
