import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import { IconButton } from "@material-ui/core";
import { ADMIN_ROLE_ID, IRole, ISheet, SheetsMap } from "../../types";
import {
  firstLetterToUpperCase,
  parseNameToPath,
} from "../../utils/formatters";
import AdminDrawerSection from "./AdminDrawerSection";
import { handleGoToHomeClick, handleSheetButtonClick } from "./utils";

interface Props {
  visibleSheets: SheetsMap;
  role: IRole;
}

export default function TemporaryDrawer(props: Props) {
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(!state);
    };

  const list = (roleId: number) => (
    <Box
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        <ListItem
          key="menu-item-first"
          onClick={() => handleGoToHomeClick(navigate, props.visibleSheets)}
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {props.visibleSheets && Object.values(props.visibleSheets).map((s: ISheet, i: number) => {
          // sanitize name for path: make name all lower case
          // and replace spaces by underscore
          const pathName = parseNameToPath(s.name);
          return (
            <ListItem
              key={`menu-item-${i}`}
              onClick={() =>
                handleSheetButtonClick(
                  navigate,
                  s.sheetId,
                  pathName,
                  s.name,
                  props.visibleSheets,
                  props.role
                )
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={firstLetterToUpperCase(s.name)} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {roleId == ADMIN_ROLE_ID && <AdminDrawerSection />}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <IconButton
          size="medium"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer()}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor={"left"} open={state} onClose={toggleDrawer()}>
          {list(props.role.roleId)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
