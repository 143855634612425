import { useState, useEffect } from "react";

const useColumnVisibility = (
  initialColumns: string[],
  localStorageKey: string
) => {
  // Load column visibility state from localStorage or use initialColumns
  const storedColumns = localStorage.getItem(localStorageKey);
  const initialVisibility = storedColumns
  ? JSON.parse(storedColumns)
  : initialColumns;
  
  const [visibleColumns, setVisibleColumns] = useState<string[]>(initialVisibility);

  const toggleColumnVisibility = (columnName: string) => {
    setVisibleColumns((prevColumns: string[]) => {
      const updatedColumns = prevColumns.includes(columnName)
        ? prevColumns.filter((col) => col !== columnName)
        : [...prevColumns, columnName];

      // Persist updatedColumns in localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(updatedColumns));

      return updatedColumns;
    });
  };

  // Reset column visibility to initialColumns and remove localStorage item
  const resetColumnVisibility = () => {
    localStorage.removeItem(localStorageKey);
    setVisibleColumns(initialColumns);
  };

  useEffect(() => {
    setVisibleColumns(initialVisibility);
  }, [localStorageKey]);

  return { visibleColumns, toggleColumnVisibility, resetColumnVisibility };
};

export default useColumnVisibility;
