import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import ApprovalIcon from "@mui/icons-material/Approval";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DescriptionIcon from "@mui/icons-material/Description";
import InventoryIcon from "@mui/icons-material/Inventory";
import { AdminSection } from "../../types";
import { lang } from "../../lang";

export const AdminSectionIcons = {
  [AdminSection.Users]: (style?: any) => <GroupIcon sx={style} />,
  [AdminSection.Roles]: (style?: any) => <PlaylistAddCheckIcon sx={style} />,
  [AdminSection.Status]: (style?: any) => <ApprovalIcon sx={style} />,
  [AdminSection.Sheets]: (style?: any) => <DescriptionIcon sx={style} />,

  // Transalted
  [lang(AdminSection.Users)]: (style?: any) => <GroupIcon sx={style} />,
  [lang(AdminSection.Roles)]: (style?: any) => (
    <PlaylistAddCheckIcon sx={style} />
  ),
  [lang(AdminSection.Status)]: (style?: any) => <ApprovalIcon sx={style} />,
  [lang(AdminSection.Sheets)]: (style?: any) => <DescriptionIcon sx={style} />,

  // Business specific
  [AdminSection.Productos]: (style?: any) => <InventoryIcon sx={style} />,
};
