import { langData } from "./data";

const currentLanguage = (process.env.REACT_APP_LANG || "en") as "es" | "en";

// Create a function to retrieve text based on the section and the current language
export const lang = (section: string): string => {
  if (langData[section] && langData[section][currentLanguage]) {
    return langData[section][currentLanguage];
  }
  // if no translation, return the same as input
  return section;
};
