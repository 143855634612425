import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

const FormikSwitch = (props: { formik: any; field: string; label: string }) => {
  return (
    <FormControlLabel
      key={"fc-sw-lbl"}
      control={
        <Switch
          {...props}
          checked={props.formik.values[props.field]}
          onChange={(event) => {
            const checked = event.target.checked;
            props.formik.setFieldValue(props.field, checked);
          }}
          color="primary"
          key={"sw-opt"}
        />
      }
      label={props.label}
      labelPlacement="start"
    />
  );
};

export default FormikSwitch;
