import _ from "lodash";
import { lang } from "../lang";
import {
  AdminSection,
  BusinessColumns,
  IProducto,
  IRole,
  IStatus,
  RoleColumns,
  SheetColumns,
  StatusColumns,
  UserColumns,
} from "../types";

export const defaultFormatter = {
  value: (value: string): string => value,
  column: "columnMedium",
};

export const parseNameToPath = (sheetName: string) =>
  sheetName.toLowerCase().replace(/\s+/g, "_");

export const firstLetterToUpperCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatColumnName = (str: string): string => {
  const capitalized = firstLetterToUpperCase(str.split("_").join(" "));
  return lang(capitalized);
};

// Used to format the creator metadata columns
export const formatCreatorColumnName = (str: string): string => {
  const capitalized = firstLetterToUpperCase(str.split("_").join(" "));
  return lang(`Creator${capitalized}`);
};

const shortDateFormatter = (dateStr?: string): string => {
  if (!dateStr) {
    return "-";
  }
  const date = new Date(dateStr);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based

  return `${day}/${month}`;
};

const dateFormatter = (dateStr?: string): string => {
  if (!dateStr) {
    return "-";
  }
  const date = new Date(dateStr);
  const year = String(date.getUTCFullYear()).slice(-2); // Extract the last two digits of the year

  return `${shortDateFormatter(dateStr)}/${year}`;
};

// Formats for the specified timezone (records saved on UTC)
const dateHourFormatter = (dateStr?: string): string => {
  if (!dateStr) {
    return "-";
  }
  const timeZone = `Etc/${process.env.REACT_APP_TIMEZONE}`;
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
  };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);
  return formattedDate;
};

const fullNameFormatter = (user: {
  name: string;
  lastName: string;
}): string => {
  // in case the user is null (deleted), set as deleted user
  if (!user) {
    user = { name: "-", lastName: "" };
  }
  return `${firstLetterToUpperCase(user.name)} ${firstLetterToUpperCase(
    user.lastName
  )}`;
};

const columnsStrFormatter = (colStr: string) =>
  colStr === "*" ? lang("all") : colStr;

const sheetsFormatter = (ids: number[], maps: { [key: string]: any }): string =>
  entityMapFormatter(ids, maps[AdminSection.Sheets]);

const statusesFormatter = (
  ids: number[],
  maps: { [key: string]: any }
): string => entityMapFormatter(ids, maps[AdminSection.Status]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const entityMapFormatter = (ids: number[], entityMap: any): string => {
  if (_.isEmpty(entityMap) || !ids) return "N/A";
  return ids.length
    ? ids
        .map(
          (id) => entityMap[id] && firstLetterToUpperCase(entityMap[id].name)
        )
        .join(", ")
    : "N/A";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const entityArrayFormatter = (entities: any[]): string =>
  (entities &&
    entities.map((e) => firstLetterToUpperCase(e.name)).join(", ")) ||
  "N/A";

const statusArrayFilterFormatter = (
  id: number,
  entities: IStatus[]
): string => {
  const entity = entities.filter((e) => e.statusId == id);
  if (!entity.length) {
    return "N/A";
  }
  return entity[0].name.toUpperCase();
};

const formatBool = (v: boolean): string => (v ? lang("Yes") : "No");

export const formatters: {
  [key: string]: {
    value: (rawVal: any, entityMap?: any) => any;
    column:
      | "columnBig"
      | "columnMedium"
      | "columnSmall"
      | "columnFixed"
      | "columnFixedBig";
  };
} = {
  [BusinessColumns.CREATED_AT]: {
    value: dateHourFormatter,
    column: "columnFixedBig",
  },
  [BusinessColumns.UPDATED_AT]: {
    value: dateHourFormatter,
    column: "columnFixedBig",
  },
  [BusinessColumns.CREATOR]: {
    value: fullNameFormatter,
    column: "columnFixed",
  },
  [BusinessColumns.EDITOR]: {
    value: fullNameFormatter,
    column: "columnFixed",
  },
  [UserColumns.ROLE]: {
    value: (role: IRole): string =>
      role ? firstLetterToUpperCase(role.name) : "-",
    column: "columnMedium",
  },
  [UserColumns.NAME]: {
    value: firstLetterToUpperCase,
    column: "columnMedium",
  },
  [UserColumns.LAST_NAME]: {
    value: firstLetterToUpperCase,
    column: "columnMedium",
  },
  [UserColumns.EMAIL]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [UserColumns.INACTIVE_SINCE]: {
    value: dateHourFormatter,
    column: "columnFixed",
  },
  [UserColumns.ACTIVE]: {
    value: formatBool,
    column: "columnSmall",
  },
  [UserColumns.PICTURE]: {
    value: (val: string) => val,
    column: "columnFixed",
  },
  [RoleColumns.ONLY_OWN_RECORDS]: {
    value: formatBool,
    column: "columnSmall",
  },
  [RoleColumns.EDIT_ONLY]: {
    value: formatBool,
    column: "columnSmall",
  },
  [RoleColumns.VISIBLE_COLUMNS]: {
    value: columnsStrFormatter,
    column: "columnBig",
  },
  [RoleColumns.EDITABLE_COLUMNS]: {
    value: columnsStrFormatter,
    column: "columnBig",
  },
  [RoleColumns.VISIBLE_SHEETS]: {
    value: sheetsFormatter,
    column: "columnMedium",
  },
  [StatusColumns.DERIVATIONS]: {
    value: statusesFormatter,
    column: "columnMedium",
  },
  [StatusColumns.SHEET_ID]: {
    value: sheetsFormatter,
    column: "columnSmall",
  },
  [SheetColumns.STATUSES]: {
    value: entityArrayFormatter,
    column: "columnMedium",
  },

  // Business specific
  [BusinessColumns.VERIFICADOR]: {
    value: fullNameFormatter,
    column: "columnFixedBig",
  },
  [BusinessColumns.AUDITOR]: {
    value: fullNameFormatter,
    column: "columnFixed",
  },
  [BusinessColumns.FECHA_NACIMIENTO]: {
    value: dateFormatter,
    column: "columnFixedBig",
  },
  [BusinessColumns.CITA]: {
    value: dateHourFormatter,
    column: "columnFixed",
  },
  [BusinessColumns.FECHA_AUDICION]: {
    value: shortDateFormatter,
    column: "columnBig",
  },
  [BusinessColumns.FECHA_CARGA]: {
    value: shortDateFormatter,
    column: "columnBig",
  },
  [BusinessColumns.FECHA_CUMP]: {
    value: shortDateFormatter,
    column: "columnBig",
  },
  [BusinessColumns.FECHA_SAT]: {
    value: shortDateFormatter,
    column: "columnMedium",
  },
  [BusinessColumns.STATUS_ID]: {
    value: statusArrayFilterFormatter,
    column: "columnMedium",
  },
  [BusinessColumns.NRO_CLIENTE]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.TEL_CONTACTO]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.DNI_CONTACTO]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.EMAIL_CONTACTO]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.ENTRE_CALLES]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.DIR_INSTALACION]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.MODO_PAGO]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.TOTALIZACION]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.OBSERVACIONES]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.OBSERVACIONES_VERIFICADOR]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.ESTADO_CARGA]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.INFORME_TOA]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.PORCENTAGE]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.TEL_SERVICIO]: {
    value: defaultFormatter.value,
    column: "columnBig",
  },
  [BusinessColumns.PRODUCTO]: {
    value: (prod: IProducto | null) => (prod ? prod.nombre : "-"),
    column: "columnFixed",
  },
};

export const getXlsxFileName = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  const formattedDate = `${year}${month}${day}${hours}${minutes}`;
  const fileName = `data_${formattedDate}.xlsx`;
  return fileName;
};
