import { useEffect } from "react";
import { AdminSection, Events, GenericMap, IRole, ISheet } from "../../types";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { Socket } from "socket.io-client";
import {
  getStoredItem,
  storeMap,
  refreshStoredState,
} from "../../state/localstorage";

interface Props {
  setSheetsMap: React.Dispatch<
    React.SetStateAction<GenericMap<ISheet>>
  >;
}

const useSheetWSEvents = ({ setSheetsMap }: Props): [Socket | null] => {
  const { socket } = useWebSocketContext();
  useEffect(() => {
    const eventHandlers: Record<string, (...args: any[]) => void> = {};

    if (socket) {
      eventHandlers[Events.SheetCreated] = async (newRecord: ISheet) => {
        // Refresh the cached sheets
        const sheetsMaps = getStoredItem(AdminSection.Sheets) as
          | GenericMap<ISheet>
          | undefined;
        if (!sheetsMaps) {
          await refreshStoredState(AdminSection.Sheets);
          const sm = getStoredItem(AdminSection.Sheets) as GenericMap<ISheet>;
          setSheetsMap(sm);
          return;
        }
        if (!sheetsMaps[newRecord.sheetId]) {
          sheetsMaps[newRecord.sheetId] = newRecord;
          localStorage.setItem(AdminSection.Sheets, JSON.stringify(sheetsMaps));
        }
        setSheetsMap(sheetsMaps);
      };

      eventHandlers[Events.SheetUpdated] = async (updatedRecord: ISheet) => {
        // Refresh the cached sheets
        const sheetsMaps = getStoredItem(AdminSection.Sheets) as
          | GenericMap<ISheet>
          | undefined;
        if (!sheetsMaps) {
          await refreshStoredState(AdminSection.Sheets);
          const sm = getStoredItem(AdminSection.Sheets) as GenericMap<ISheet>;
          setSheetsMap(sm);
          return;
        }
        sheetsMaps[updatedRecord.sheetId] = updatedRecord;
        localStorage.setItem(AdminSection.Sheets, JSON.stringify(sheetsMaps));
        setSheetsMap(sheetsMaps);
      };

      eventHandlers[Events.SheetDeleted] = async () => {
        const role = getStoredItem("role") as IRole;
        // Refresh the cached sheets
        const sheetsMap = await storeMap<ISheet>(AdminSection.Sheets, role);
        setSheetsMap(sheetsMap);
      };

      // Register the event handlers with the socket
      Object.keys(eventHandlers).forEach((eventName) => {
        socket.on(eventName, eventHandlers[eventName]);
      });
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (socket) {
        // Remove all event listeners from the socket
        Object.keys(eventHandlers).forEach((eventName) => {
          socket.off(eventName, eventHandlers[eventName]);
        });
      }
    };
  }, [socket]);
  return [socket];
};

export default useSheetWSEvents;
