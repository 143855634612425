import { HttpStatusCode } from "axios";
import { Socket } from "socket.io-client";
import apiClient, { ApiPath } from "./client";
import { IRole } from "../../types";
import jwt_decode from "jwt-decode";
import { wsLogout } from "../ws/auth";

export const loginWithPassword = async (
  email: string,
  password: string
): Promise<IRole> => {
  const response = await apiClient.post(ApiPath.PASSWORD_LOGIN, {
    email,
    password,
  });
  if (!response || response.status !== HttpStatusCode.Ok) {
    throw new Error("Error on authentication");
  }
  storeLoginData(response.data);
  return response.data.role;
};

export const loginWithGoogle = async (token: string): Promise<IRole> => {
  const response = await apiClient.post(ApiPath.GOOGLE_LOGIN, {
    token,
  });
  if (!response || response.status !== HttpStatusCode.Ok) {
    throw new Error("Error on authentication");
  }
  storeLoginData(response.data);
  return response.data.role;
};

const storeLoginData = (data: { token: string; role: IRole }) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("role", JSON.stringify(data.role));
  const decoded = jwt_decode(data.token) as any;
  localStorage.setItem(
    "user",
    JSON.stringify({
      userId: decoded.userId,
      name: decoded.fullName,
      picture: decoded.picture,
      active: decoded.active,
    })
  );
};

export const logout = (socket?: Socket | null) => {
  wsLogout(socket);
  localStorage.clear();
};

export const isAuthenticated = async (): Promise<boolean> => {
  const response = await apiClient.get(ApiPath.TOKEN_VALIDATION);
  if (!response || response.status !== HttpStatusCode.Ok) {
    return false;
  }
  return response.data;
};
