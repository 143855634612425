import { AxiosInstance, AxiosResponse, HttpStatusCode } from "axios";
import apiClient from "./client";

// Define a generic interface for the API response data
interface ApiResponse extends AxiosResponse {
  message: string;
}

// Define a generic class to handle API operations
class ApiHandler<T> {
  basePath: string;
  idField: string;
  apiClient: AxiosInstance;

  constructor(basePath: string, idField: string) {
    this.basePath = basePath;
    this.idField = idField;
    this.apiClient = apiClient;
  }

  protected async request<U>(
    url: string,
    method: "get" | "post" | "put" | "delete",
    data?: any
  ): Promise<U> {
    const response = await this.apiClient[method](url, data);
    if (
      response.status !== HttpStatusCode.Ok &&
      response.status !== HttpStatusCode.Created
    ) {
      throw new Error(
        `Error on ${url} request. Error: ${response.data?.message}`
      );
    }
    return response.data;
  }

  public async getAll(query?: string): Promise<T[]> {
    const path = query ? `${this.basePath}?q=${query}` : this.basePath;
    return this.request<T[]>(path, "get");
  }

  public async getById(id: number): Promise<T> {
    return this.request<T>(`${this.basePath}/${id}`, "get");
  }

  public async create(payload: T): Promise<ApiResponse> {
    return this.request<ApiResponse>(this.basePath, "post", payload);
  }

  public async update(payload: T): Promise<ApiResponse> {
    return this.request<ApiResponse>(
      `${this.basePath}/${(payload as any)[this.idField]}`,
      "put",
      payload
    );
  }

  public async delete(id: number): Promise<ApiResponse> {
    return this.request<ApiResponse>(`${this.basePath}/${id}`, "delete");
  }

  public async bulkDelete(ids: number[]): Promise<ApiResponse> {
    return this.request<ApiResponse>(this.basePath, "delete", {
      data: { ids },
    });
  }
}

export default ApiHandler;
