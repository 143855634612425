import * as React from "react";
import { alpha, makeStyles, Paper, TableContainer, Typography } from "@material-ui/core";
import DeleteButton from "../btn/DeleteButton";
import DownloadButton from "../btn/DownloadButton";
import { Toolbar } from "@mui/material";
import { AdminSectionIcons } from "../icons";
import { AdminSection } from "../../types";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchBar from "./SearchBar";
import ShowHidenColumnsButton from "../btn/ShowHiddenColumnsButton";
import { listStyle } from "../../styles";

interface TableToolbarProps {
  tableName: string;
  numSelected: number;
  isDeleteDialogOpen: boolean;
  allowSearch?: boolean;
  onDownloadClick?: () => void;
  onDeleteClick: () => void;
  closeDeleteDialog: () => void;
  confirmDelete: () => void;
  onSearch?: (query: string) => void;
  resetColumnsVisibility?: () => void;
}

const useStyles = makeStyles(() => listStyle);

export const iconStyle = {
  fontSize: 25,
  color: "gray",
  marginRight: "0.5rem",
};

function TableToolbar(props: TableToolbarProps) {
  const classes = useStyles();
  const { numSelected } = props;
  const adminSectionIcon =
    AdminSectionIcons[props.tableName.toLowerCase() as AdminSection];
  const icon = adminSectionIcon ? (
    adminSectionIcon(iconStyle)
  ) : (
    <DescriptionIcon sx={iconStyle} />
  );
  return (
    <TableContainer component={Paper} className={classes.tableToolbar}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            style={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <>
            <Typography
              style={{
                flex: "1 1 100%",
                display: "flex",
                alignItems: "center",
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {icon}
              {props.tableName}
            </Typography>
            {props.resetColumnsVisibility && (
              <ShowHidenColumnsButton
                resetVisibility={props.resetColumnsVisibility}
              />
            )}
            {props.onDownloadClick && (
              <DownloadButton onClick={props.onDownloadClick} />
            )}
          </>
        )}
        {numSelected > 0 && (
          <DeleteButton
            onClick={props.onDeleteClick}
            open={props.isDeleteDialogOpen}
            onClose={props.closeDeleteDialog}
            onConfirm={props.confirmDelete}
          />
        )}
        {!numSelected && props.onSearch && (
          <SearchBar onSearch={props.onSearch} />
        )}
      </Toolbar>
    </TableContainer>
  );
}

export default TableToolbar;
