import React from "react";
import * as Yup from "yup";
import {
  ADMIN_ROLE_ID,
  AdminEntities,
  IRole,
  IUser,
  UserColumns,
} from "../../types";
import FormikTextField from "./FormikTextField";
import { formatColumnName } from "../../utils/formatters";
import FormikSwitch from "./FormikSwitch";
import FormikDrowdownEntity from "./FormikDropdownEntity";
import { lang } from "../../lang";

export const userInitialValues: Partial<IUser> = {
  name: "",
  lastName: "",
  email: "",
  phone: "",
  govId: "",
  group: "",
  roleId: ADMIN_ROLE_ID,
  active: true,
};

export const userValidationSchema = Yup.object({
  [UserColumns.NAME]: Yup.string().required("Required"),
  [UserColumns.LAST_NAME]: Yup.string().required("Required"),
  [UserColumns.EMAIL]: Yup.string().email().required("Required"),
  [UserColumns.PHONE]: Yup.string(),
  [UserColumns.GOV_ID]: Yup.string(),
  [UserColumns.GROUP]: Yup.string(),
  [UserColumns.ROLE_ID]: Yup.number().required("Required"),
  [UserColumns.ACTIVE]: Yup.bool().required("Required"),
});

export const userFields = {
  [UserColumns.NAME]: (formik: any) => (
    <FormikTextField
      key={"txt-name-1"}
      formik={formik}
      fieldName={UserColumns.NAME}
      label={formatColumnName(UserColumns.NAME)}
    />
  ),
  [UserColumns.LAST_NAME]: (formik: any) => (
    <FormikTextField
      key={"txt-lst-name-1"}
      formik={formik}
      fieldName={UserColumns.LAST_NAME}
      label={formatColumnName(UserColumns.LAST_NAME)}
    />
  ),
  [UserColumns.EMAIL]: (formik: any) => (
    <FormikTextField
      key={"txt-email-1"}
      formik={formik}
      fieldName={UserColumns.EMAIL}
      label={formatColumnName(UserColumns.EMAIL)}
    />
  ),
  [UserColumns.PHONE]: (formik: any) => (
    <FormikTextField
      key={"txt-phone-1"}
      formik={formik}
      fieldName={UserColumns.PHONE}
      label={formatColumnName(UserColumns.PHONE)}
    />
  ),
  [UserColumns.GOV_ID]: (formik: any) => (
    <FormikTextField
      key={"txt-gov-1"}
      formik={formik}
      fieldName={UserColumns.GOV_ID}
      label={formatColumnName(UserColumns.GOV_ID)}
    />
  ),
  [UserColumns.GROUP]: (formik: any) => (
    <FormikTextField
      key={"txt-gro-1"}
      formik={formik}
      fieldName={UserColumns.GROUP}
      label={formatColumnName(UserColumns.GROUP)}
    />
  ),  
  [UserColumns.ACTIVE]: (formik: any) => (
    <FormikSwitch
      key={"sw-or"}
      formik={formik}
      field={UserColumns.ACTIVE}
      label={formatColumnName(UserColumns.ACTIVE)}
    />
  ),
  [UserColumns.ROLE_ID]: (
    formik: any,
    entities: AdminEntities,
    classes: any
  ) => (
    <FormikDrowdownEntity
      formik={formik}
      field={UserColumns.ROLE_ID}
      key={"dwn-role-1"}
      label={lang("Role")}
      options={entities.roles.map((r: IRole) => ({
        value: r.roleId,
        label: r.name.toUpperCase(),
      }))}
      classes={classes}
    />
  ),
};
