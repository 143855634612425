import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "./Copyright";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "rgba(255, 255, 255, 0.54)",
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: 'auto',  
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Copyright />
    </footer>
  );
};

export default Footer;
