import * as React from "react";
import {
  makeStyles,
  Paper,
  TablePagination,
  TableContainer,
} from "@material-ui/core";
import { listStyle } from "../../styles";

interface TablePagProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => listStyle);

function TablePag({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}: TablePagProps) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tablePagination}>
      <table>
        <tbody>
          <tr>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </tr>
        </tbody>
      </table>
    </TableContainer>
  );
}

export default TablePag;
