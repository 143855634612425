import { useEffect } from "react";
import {
  ADMIN_ROLE_ID,
  AdminSection,
  Events,
  GenericMap,
  IRole,
  IRoleVisibleSheet,
  ISheet,
} from "../../types";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { Socket } from "socket.io-client";
import {
  getStoredItem,
  refreshStoredState,
  storeMap,
} from "../../state/localstorage";
import _ from "lodash";

interface Props {
  setRole: React.Dispatch<React.SetStateAction<IRole>>;
  setRoles?: React.Dispatch<React.SetStateAction<IRole[]>>;
  setSheetsMap?: React.Dispatch<
    React.SetStateAction<GenericMap<ISheet>>
  >;
}

// these events are pushed only to the role's room and admin_room
// so, check if user role is admin. If is not admin, should update the user
// role accordingly to produce the corresponding effects
const useRoleWSEvents = ({
  setRole,
  setRoles,
  setSheetsMap,
}: Props): [Socket | null] => {
  const { socket } = useWebSocketContext();
  useEffect(() => {
    const eventHandlers: Record<string, (...args: any[]) => void> = {};

    if (socket) {
      // this will be pushed to admin room only
      eventHandlers[Events.RoleCreated] = async (newRecord: IRole) => {
        // Refresh the cached roles
        const roles = getStoredItem(AdminSection.Roles) as IRole[] | undefined;
        if (!roles) {
          await refreshStoredState(AdminSection.Roles);
          const rs = getStoredItem(AdminSection.Roles) as IRole[];
          setRoles && setRoles(rs);
          return;
        }
        const existingRole = roles.filter((r) => r.roleId == newRecord.roleId);
        if (existingRole.length) {
          return;
        }
        roles.push(newRecord);
        localStorage.setItem(AdminSection.Roles, JSON.stringify(roles));
        setRoles && setRoles(roles);
      };

      eventHandlers[Events.RoleUpdated] = async (updatedRecord: IRole) => {
        let role = getStoredItem("role") as IRole;
        // if role is not admin, it is the one that was updated
        // so update the cached role and call setRole
        if (role.roleId !== ADMIN_ROLE_ID) {
          role = updatedRecord;
          localStorage.setItem("role", JSON.stringify(updatedRecord));
          setRole(updatedRecord);
        }
        // Refresh the cached roles
        const roles = getStoredItem(AdminSection.Roles) as IRole[] | undefined;
        if (!roles) {
          await refreshStoredState(AdminSection.Roles, role);
          const rs = getStoredItem(AdminSection.Roles) as IRole[];
          setRoles && setRoles(rs);
        } else {
          // update role already cached
          for (const i in roles) {
            if (roles[i].roleId !== updatedRecord.roleId) {
              continue;
            }
            roles[i] = updatedRecord;
            localStorage.setItem(AdminSection.Roles, JSON.stringify(roles));
            setRoles && setRoles(roles);
            break;
          }
        }
      };

      eventHandlers[Events.RoleVisibleSheetsUpdated] = async (
        records: IRoleVisibleSheet[]
      ) => {
        const role = getStoredItem("role") as IRole;
        // if role is not admin, it is the one that was updated
        // so update the cached role and call setRole
        const newVisibleSheets = records.map((vs) => vs.sheetId);
        if (role.roleId !== ADMIN_ROLE_ID) {
          role.visibleSheets = newVisibleSheets;
          localStorage.setItem("role", JSON.stringify(role));
          setRole(role);
          // refresh the sheetsMap in case the visible sheets changed for the role
          const sm = await storeMap<ISheet>(AdminSection.Sheets, role);
          setSheetsMap && setSheetsMap(sm);
        }
        // Refresh the cached roles
        const roles = getStoredItem(AdminSection.Roles) as IRole[] | undefined;
        if (!roles) {
          await refreshStoredState(AdminSection.Roles, role);
          const rs = getStoredItem(AdminSection.Roles) as IRole[];
          setRoles && setRoles(rs);
        } else {
          // update role already cached
          for (const i in roles) {
            if (roles[i].roleId !== records[0].roleId) {
              continue;
            }
            roles[i].visibleSheets = newVisibleSheets;
            localStorage.setItem(AdminSection.Roles, JSON.stringify(roles));
            setRoles && setRoles(roles);
            break;
          }
        }
      };

      // this is pushed only to admin room
      eventHandlers[Events.RoleDeleted] = async () => {
        // Refresh the cached roles
        await refreshStoredState(AdminSection.Roles);
        const rs = getStoredItem(AdminSection.Roles) as IRole[];
        setRoles && setRoles(rs);
      };

      // Register the event handlers with the socket
      Object.keys(eventHandlers).forEach((eventName) => {
        socket.on(eventName, eventHandlers[eventName]);
      });
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (socket) {
        // Remove all event listeners from the socket
        Object.keys(eventHandlers).forEach((eventName) => {
          socket.off(eventName, eventHandlers[eventName]);
        });
      }
    };
  }, [socket]);
  return [socket];
};

export default useRoleWSEvents;
