import { HttpStatusCode } from "axios";
import { ApiPath } from "./client";
import { IBusiness } from "../../types";
import ApiHandler from "./handler";

interface BusinessListResponse {
  records: IBusiness[];
  totalCount: number;
}

class BusinessApiHandler extends ApiHandler<IBusiness> {
  constructor() {
    super(ApiPath.BUSINESS_RECORDS, "id");
  }

  public async getAllBySheet(
    sheetId: number,
    page?: number,
    rowsPerPage?: number
  ): Promise<BusinessListResponse> {
    const queryParams: string[] = [`sheet=${sheetId}`];

    if (typeof page !== "undefined") {
      queryParams.push(`page=${page}`);
    }

    if (typeof rowsPerPage !== "undefined") {
      queryParams.push(`limit=${rowsPerPage}`);
    }

    const response = await this.apiClient.get(
      `${this.basePath}?${queryParams.join("&")}`
    );
    if (!response || response.status !== HttpStatusCode.Ok) {
      throw new Error(`Error on ${this.basePath} list request`);
    }
    return response.data;
  }

  public async getByIdAndSheet(
    sheetId: number,
    id: number
  ): Promise<IBusiness> {
    const response = await this.apiClient.get(
      `${this.basePath}/${id}?sheet=${sheetId}`
    );
    if (!response || response.status !== HttpStatusCode.Ok) {
      throw new Error(`Error on ${this.basePath} get by id request`);
    }
    return response.data;
  }

  // getColumns returns the avaliable columns of the business table
  // This is used to allow admins select the role's visible and editable
  // columns
  public async getColumns(): Promise<string[]> {
    const response = await this.apiClient.get(ApiPath.BUSINESS_COLUMNS);
    if (!response || response.status !== HttpStatusCode.Ok) {
      throw new Error(`Error on ${ApiPath.BUSINESS_COLUMNS} request`);
    }
    return response.data;
  }
}

export default new BusinessApiHandler();
