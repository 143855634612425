import { useEffect } from "react";
import { Events, IBusiness } from "../../types";
import { useWebSocketContext } from "../../context/WebSocketContext";

interface Props {
  sheetId: number;
  setRecords: React.Dispatch<React.SetStateAction<IBusiness[]>>;
  fetchRecords: (sheetId: number) => Promise<void>;
}

const useBusinessWSEvents = ({
  sheetId,
  setRecords,
  fetchRecords,
}: Props): void => {
  const { socket } = useWebSocketContext();
  useEffect(() => {
    const eventHandlers: Record<string, (...args: any[]) => void> = {};

    if (socket) {
      eventHandlers[`${Events.BusinessCreated}-${sheetId}`] = (
        newRecord: IBusiness
      ) => {
        setRecords((records) => [...records, newRecord]);
      };

      eventHandlers[`${Events.BusinessUpdated}-${sheetId}`] = (
        updatedRecord: IBusiness
      ) => {
        // update the corresponding record
        setRecords((records) =>
          records.map((r) => (r.id === updatedRecord.id ? updatedRecord : r))
        );
      };

      eventHandlers[`${Events.BusinessSheetChange}-${sheetId}`] = (record: {
        id: number;
      }) => {
        // remove the record that changed status
        setRecords((records) => records.filter((r) => r.id !== record.id));
      };

      eventHandlers[Events.BusinessDeleted] = async () => {
        await fetchRecords(sheetId);
      };

      // Register the event handlers with the socket
      Object.keys(eventHandlers).forEach((eventName) => {
        socket.on(eventName, eventHandlers[eventName]);
      });
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (socket) {
        // Remove all event listeners from the socket
        Object.keys(eventHandlers).forEach((eventName) => {
          socket.off(eventName, eventHandlers[eventName]);
        });
      }
    };
  }, [sheetId, socket]);
};

export default useBusinessWSEvents;
