import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Paper, Typography } from "@material-ui/core";
import {
  adminFormFields,
  adminInitialVals,
  adminValSchema,
  formStyles,
} from "../../components/form";
import {
  ADMIN_ROLE,
  AdminEntity,
  AdminSection,
  AdminSectionCols,
  AdminSectionIdCol,
  GenericMap,
  IRole,
  ISheet,
  IStatus,
  Path,
  RoleColumns,
  apiServices,
} from "../../types";
import { defaultSeparator } from "../../utils";
import { getStoredItem, refreshStoredState } from "../../state/localstorage";
import { formatColumnName } from "../../utils/formatters";
import GoBackButton from "../../components/btn/GoBackButton";
import SaveButton from "../../components/btn/SaveButton";
import ButtonContainer from "../../components/btn/ButtonContainer";
import { SnackbarAction, setSuccessfulOpFlag } from "../../components/form/SuccessSnackbar";
import useRoles from "../../hooks/roles";
import { lang } from "../../lang";
import * as _ from "lodash";
import BasePage from "../../components/page/BasePage";
import FormSkeleton from "../../components/form/FormSkeleton";

const useStyles = formStyles;

const AdminEditPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { section } = useParams();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { roles, error: rolesApiError } = useRoles();

  if (rolesApiError) {
    setError(rolesApiError);
  }

  const { id } = useParams();
  if (!id) {
    navigate(Path.ROOT);
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const recordId = parseInt(id!);

  // current logic only allows to create on status 1
  const idCol = AdminSectionIdCol[section as AdminSection];
  const apiService = apiServices[section as AdminSection];
  // get all the fields for the current entity
  const fields = Object.values(AdminSectionCols[section as AdminSection]);

  const initValues = adminInitialVals[section as AdminSection];
  const validationSchema = adminValSchema[section as AdminSection];
  const formFields = adminFormFields[section as AdminSection];

  // get status and roles for the forms
  const sheetsMap = getStoredItem(AdminSection.Sheets) as GenericMap<ISheet>;
  const statusesMap = getStoredItem(AdminSection.Status) as GenericMap<IStatus>;
  // this is set on login when user is admin
  // if not present, redirect to main menu
  if (_.isEmpty(sheetsMap) || _.isEmpty(statusesMap)) {
    navigate(Path.ROOT);
  }

  const sheets = Object.values(sheetsMap);
  let statuses = Object.values(statusesMap);

  // in case of status, remove the same status as a derivations option
  if ((section as AdminSection) == AdminSection.Status) {
    statuses = statuses.filter((s: IStatus) => s.statusId !== recordId);
  }

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        // the API returns only the visible data for the user
        const data = (await apiService.getById(recordId)) as any;
        // for editableCols & visible cols (role), is a string
        // with the list of columns separated by a comma
        // need to split the string to create the array
        if ((section as AdminSection) == AdminSection.Roles) {
          if (data[RoleColumns.EDITABLE_COLUMNS]) {
            data[RoleColumns.EDITABLE_COLUMNS] =
              data[RoleColumns.EDITABLE_COLUMNS].split(defaultSeparator);
          }
          if (data[RoleColumns.VISIBLE_COLUMNS]) {
            data[RoleColumns.VISIBLE_COLUMNS] =
              data[RoleColumns.VISIBLE_COLUMNS].split(defaultSeparator);
          }
        }
        formik.setValues(data); // Set the fetched record as form values
      } catch (error: any) {
        console.log(error.response?.data?.message);
        setError(error.response?.data?.message || lang("UnexpectedError"));
      }
    };
    fetchRecord();
    setLoading(false);
  }, [id]);

  const formik = useFormik({
    initialValues: initValues as AdminEntity,
    validationSchema,
    onSubmit: (values: AdminEntity) => {
      handleSubmit(values as any);
    },
  });

  const handleSubmit = async (values: AdminEntity) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      values[idCol] = recordId;
      await apiService.update(values as any);
      // after update a role/status,
      // refresh the roles saves in local storage
      await refreshStoredState(section as AdminSection);
      setSuccessfulOpFlag(SnackbarAction.EDIT);

      navigate(`/${section as AdminSection}`);
    } catch (error: any) {
      console.log(error.response?.data?.message);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    }
  };

  return (
    <BasePage role={ADMIN_ROLE as IRole} sheetsMap={sheetsMap} addBtn={false}>
      <Paper className={classes.root}>
        <Typography variant="h6" id="formTitle" component="div">
          {`${lang("EditRecord")} ${formatColumnName(section as AdminSection)}`}
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        {loading && <FormSkeleton fieldCount={fields.length} />}
        {!loading && (
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            {fields.map((c: string) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              const fieldComponent = formFields[c];
              if (!fieldComponent) {
                return;
              }
              return fieldComponent(
                formik,
                { roles, sheets, statuses },
                classes
              );
            })}
            <ButtonContainer>
              <SaveButton key={"create-adm-btn-1"} />
              <GoBackButton key={"create-adm-btn-2"} />
            </ButtonContainer>
          </form>
        )}
      </Paper>
    </BasePage>
  );
};

export default AdminEditPage;
