import axios from "axios";

export enum ApiPath {
  PASSWORD_LOGIN = "/api/auth/password-login",
  GOOGLE_LOGIN = "/api/auth/google-login",
  TOKEN_VALIDATION = "/api/auth/token",
  SHEETS = "/api/sheets",
  STATUS = "/api/statuses",
  USERS = "/api/users",
  ROLES = "/api/roles",
  BUSINESS_RECORDS = "/api/business/records",
  BUSINESS_COLUMNS = "/api/business/columns",
  PRODUCTOS = "/api/productos",
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});

// use JWT token for requests
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiClient;
