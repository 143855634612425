import React from "react";
import { Navigate, Route, Routes, createBrowserRouter } from "react-router-dom";
import { ADMIN_ROLE_ID, AdminSection, Path } from "../../types";
import MenuPage from "../../pages/MenuPage";
import ListPage from "../../pages/ListPage";
import CreatePage from "../../pages/CreatePage";
import EditionPage from "../../pages/EditPage";
import AdminListPage from "../../pages/admin/ListPage";
import AdminCreatePage from "../../pages/admin/CreatePage";
import AdminEditPage from "../../pages/admin/EditPage";
import { WebSocketProvider } from "../../context/WebSocketContext";
import LoginPage from "../../pages/LoginPage";
import AuthRoute from "./AuthRoute";

interface RouteProps {
  path: string;
  element: JSX.Element;
}

const businessRoutes: RouteProps[] = [
  {
    path: Path.ROOT,
    element: <MenuPage isAdmin={false} />,
  },
  {
    path: `${Path.LIST}/:sheet`,
    element: <ListPage />,
  },
  {
    path: Path.CREATE,
    element: <CreatePage />,
  },
  {
    path: `${Path.EDIT}/:id`,
    element: <EditionPage />,
  },
];

const adminRoutes: RouteProps[] = [
  {
    path: Path.ADMIN,
    element: <MenuPage isAdmin={true} />,
  },
  {
    path: `/:section`,
    element: <AdminListPage />,
  },
  {
    path: `/:section${Path.CREATE}`,
    element: <AdminCreatePage />,
  },
  {
    path: `/:section/:id`,
    element: <AdminEditPage />,
  },
];

export const router = createBrowserRouter([
  {
    path: "*",
    Component: () => (
      <WebSocketProvider>
        <Routes>
          {businessRoutes.map((r: RouteProps, i: number) => (
            <Route
              {...r}
              key={`biz-${i}`}
              element={<AuthRoute>{r.element}</AuthRoute>}
            />
          ))}
          {adminRoutes.map((r: RouteProps, i: number) => (
            <Route
              {...r}
              key={`adm-${i}`}
              element={
                <AuthRoute roleId={ADMIN_ROLE_ID}>{r.element}</AuthRoute>
              }
            />
          ))}
          <Route key={"rt-rt"} path="*" element={<Navigate to={Path.ROOT} />} />
        </Routes>
      </WebSocketProvider>
    ),
  },
  {
    path: Path.LOGIN,
    Component: () => <LoginPage />,
  },
]);
