import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { Paper, Typography } from "@material-ui/core";
import businessApi from "../services/api/business";
import {
  BusinessColumns,
  GenericMap,
  IBusiness,
  IOption,
  IRole,
  ISheet,
  IStatus,
  Path,
  userRoleColumns,
} from "../types";
import React from "react";
import { defaultSeparator, getProdOptions, getStatusOptions } from "../utils";
import {
  businessValidationSchema,
  businessFields,
} from "../components/form/business";
import { formStyles } from "../components/form";
import GoBackButton from "../components/btn/GoBackButton";
import SaveButton from "../components/btn/SaveButton";
import ButtonContainer from "../components/btn/ButtonContainer";
import {
  SnackbarAction,
  setSuccessfulOpFlag,
} from "../components/form/SuccessSnackbar";
import useRoles from "../hooks/roles";
import useUsersByRole from "../hooks/usersByRole";
import { lang } from "../lang";
import BasePage from "../components/page/BasePage";
import FormSkeleton from "../components/form/FormSkeleton";
import useProductos from "../hooks/productos";

const useStyles = formStyles;

const EditionPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [record, setRecord] = useState<IBusiness>();
  const [statusOptions, setStatusOptions] = useState<IOption[]>([]);
  const [error, setError] = useState("");
  const [sheet, setSheet] = useState<ISheet>(location.state?.sheet);
  const [loading, setLoading] = useState(true);
  const { roles, error: rolesApiError } = useRoles();

  if (rolesApiError) {
    setError(rolesApiError);
  }

  const id = location.state?.id as number;
  const tableName = location.state?.tableName as string;
  const tablePath = location.state?.tablePath as string;
  const role = location.state?.role as IRole;
  const visibleSheets = location.state?.visibleSheets as GenericMap<ISheet>;

  let editableCols = (role.editableColumns as string).split(defaultSeparator);

  if (role.editableColumns == "*") {
    editableCols = Object.keys(businessFields);
  }

  // ATM business columns have 'auditor' & 'verificador'
  // which refer to users with that role
  const rolesToFetch = [];
  for (const col of editableCols) {
    for (const roleName of userRoleColumns) {
      if (col !== roleName) {
        continue;
      }
      rolesToFetch.push(roleName);
    }
  }
  // we'll need to fetch the users for these roles for the dropdown menu
  // in the form
  const { usersByRole, loadingUsers } = useUsersByRole(rolesToFetch, roles);

  // get productos options for the dropdown menu to fill the 'producto' field
  const { productos, loadingProductos, prodErr } = useProductos();

  const formik = useFormik({
    initialValues: {
      statusId: record?.statusId || "", // Set an initial value for statusId
    } as IBusiness,
    validationSchema: businessValidationSchema,
    onSubmit: (values: IBusiness) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const fetchRecord = async () => {
      if (record) {
        return;
      }
      try {
        // the API returns only the visible data for the user
        const data = await businessApi.getByIdAndSheet(sheet.sheetId, id);
        // business specific logic to set defaults
        updateDefaults(data);
        setRecord(data);
        formik.setValues(data); // Set the fetched record as form values

        const currentSheet = visibleSheets[sheet.sheetId] as ISheet;
        if (!currentSheet) {
          setError(lang("SheetNotAvailable"));
          return;
        }
        setSheet(currentSheet);
        const status = currentSheet.statuses?.filter(
          (s: IStatus) => s.statusId == data.statusId
        );
        if (!status?.length) {
          setError(lang("StatusNotAvailable"));
          return;
        }
        // Show the possible statuses to be updated to
        // based on current status and visible statuses for the user
        setStatusOptions(getStatusOptions(visibleSheets, status[0]));
        setLoading(false);
      } catch (error: any) {
        console.log(error.response?.data?.message || error);
        setError(error.response?.data?.message || lang("UnexpectedError"));
      }
    };
    // if current user has a role within one of the userRoleColumns
    // update him/her as default value if the current value is null
    const updateDefaults = (data: IBusiness) => {
      const colName = role.name.toLowerCase();
      if (
        userRoleColumns.includes(colName as BusinessColumns) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        !data[`${colName}Id`]
      ) {
        const token = localStorage.getItem("token");
        if (!token) {
          return;
        }
        const decoded = jwt_decode(token) as any;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        data[`${colName}Id`] = decoded.userId || null;
      }
    };
    fetchRecord();
  }, [id, formik, record]);

  // after successfull update, redirect to the list page
  const handleSubmit = async (values: IBusiness) => {
    try {
      await businessApi.update(values);
      setSuccessfulOpFlag(SnackbarAction.EDIT);
      navigate(`${Path.LIST}/${tablePath}`, {
        state: {
          sheetId: sheet.sheetId,
          sheet,
          tableName,
          role,
          visibleSheets,
        },
      });
    } catch (error: any) {
      console.log(error.response?.data?.message || error);
      setError(error.response?.data?.message || lang("UnexpectedError"));
    }
  };

  return (
    <BasePage role={role} sheetsMap={visibleSheets} addBtn={false}>
      <Paper className={classes.root}>
        <Typography variant="h6" id="formTitle" component="div">
          {`${lang("EditRecord")} ${tableName}`}
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        {prodErr && <Typography color="error">{prodErr}</Typography>}
        {(loading || loadingUsers || loadingProductos) && (
          <FormSkeleton fieldCount={editableCols.length} />
        )}
        {!(loading || loadingUsers || loadingProductos) && record && (
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            {/* iterate thru visible (& editable) columns to make the form */}
            {editableCols.map((c: string) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              const fn = businessFields[c];
              if (!fn) {
                console.error(`missing form field for ${c}`);
                return;
              }
              // usersByRole & classes are only used if there's a business column
              // that refers to a role. So the users by role will be
              // used for a Dropdown field in the form
              return fn(
                formik,
                {
                  statusOptions,
                  usersByRole,
                  productosOptions: getProdOptions(productos),
                },
                classes
              );
            })}
            <ButtonContainer>
              <SaveButton key={"edit-btn-1"} />
              <GoBackButton key={"edit-btn-2"} />
            </ButtonContainer>
          </form>
        )}
      </Paper>
    </BasePage>
  );
};

export default EditionPage;
