import React from "react";
import { TextField } from "@material-ui/core";
import { FormikValues } from "formik";

const FormikTextField = (props: {
  formik: FormikValues;
  fieldName: string;
  label: string;
  type?: string;
}) => {
  return (
  <TextField
    id={props.fieldName}
    name={props.fieldName}
    label={props.label}
    variant="outlined"
    margin="normal"
    key={`text-${props.fieldName}-1`}
    type={props.type || "text"}
    fullWidth
    value={props.formik.values[props.fieldName]}
    onChange={props.formik.handleChange}
    onBlur={props.formik.handleBlur}
    error={
      props.formik.touched[props.fieldName] &&
      Boolean(props.formik.errors[props.fieldName])
    }
    helperText={
      props.formik.touched[props.fieldName] &&
      props.formik.errors[props.fieldName]
    }
  />
)};

export default FormikTextField;
