import {
  IBusiness,
  IUser,
  Order,
  IOption,
  UserFilterField,
  QuerySearchFields,
  IRole,
  AdminSection,
  apiServices,
  GenericMap,
  ADMIN_ROLE_ID,
  SheetColumns,
  StatusColumns,
  IStatus,
  ISheet,
  IProducto,
} from "../types";

import { firstLetterToUpperCase } from "./formatters";
import { monthsMap } from "../components/table/MonthFilter";

// defaultSeparator used in visibleColumns and editableColumns
export const defaultSeparator = /, |,/;

export function filterRecords<T>(
  records: T[],
  filtersSelection: { [key: string]: number[] },
  activeMonthsCreated: string[],
  activeMonthsUpdated?: string[],
  searchQuery?: string
): T[] {
  return records.filter((r) => {
    // check each of the filters to determine if the record is filtered or not
    const filterResults = getFiltersResult<T>(r, filtersSelection);
    // Check if any user filter excludes the record
    if (filterResults.includes(false)) {
      return false;
    }

    // Check active months for createdAt
    const createdAt = "createdAt" as keyof T;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const activeMonthsIdsCreated = activeMonthsCreated.map((m) => monthsMap[m]);
    if (
      activeMonthsIdsCreated.length &&
      !activeMonthsIdsCreated.includes(
        new Date(r[createdAt] as string).getMonth()
      )
    ) {
      return false;
    }

    // Check active months for updatedAt
    if (activeMonthsUpdated && activeMonthsUpdated.length) {
      const updatedAt = "updatedAt" as keyof T;
      const activeMonthsIdsUpdated = activeMonthsUpdated.map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        (m) => monthsMap[m]
      );
      if (
        !activeMonthsIdsUpdated.includes(
          new Date(r[updatedAt] as string).getMonth()
        )
      ) {
        return false;
      }
    }

    // search query logic
    if (searchQuery) {
      const querySearchResult = querySearchFilter(r, searchQuery);
      // Check if any user filed contains the search query
      // if only one of the QuerySearchFields has the search query,
      // keep that record
      if (!querySearchResult.includes(true)) {
        return false;
      }
    }

    // If all conditions pass, include the record in the filtered result
    return true;
  });
}

function getFiltersResult<T>(
  record: T,
  filtersSelection: { [key: string]: number[] }
): boolean[] {
  // Initialize flags for each user filter
  return Object.entries(filtersSelection).map(([key, ids]) => {
    // Handle the built-in user filters (creator, validator, editor)
    const idField = `${key}Id` as keyof T;
    // handle deleted user filter
    if (!record[idField]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      record[idField] = 0;
    }
    return ids.includes(record[idField] as number);
  });
}

function querySearchFilter<T>(record: T, query: string): boolean[] {
  return QuerySearchFields.map((f) => {
    const field = f as keyof T;
    if (!record[field]) {
      return false;
    }
    return (record[field] as string)
      .toLowerCase()
      .includes(query.toLowerCase());
  });
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === null && a[orderBy] !== null) {
    return -1; // Place null values at the end
  }
  if (b[orderBy] !== null && a[orderBy] === null) {
    return 1; // Place null values at the end
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * getUsersMap returns a map that contains users based on their nature
 * (e.g, creator, editor). This is used for filter options in the ListPage
 * @param records
 * @returns
 */
export function getUsersMap(records: IBusiness[]): Record<string, IOption[]> {
  const res: Record<string, IOption[]> = {};
  for (const r of records) {
    for (const field of UserFilterField) {
      if (field in r) {
        const user = r[field] as IUser | null;

        const data: IOption = {
          value: user?.userId || 0,
          label:
            user?.name && user?.lastName
              ? `${firstLetterToUpperCase(user.name)} ${firstLetterToUpperCase(
                  user.lastName
                )}`
              : "-",
        };

        if (!(field in res)) {
          res[field] = [];
        }
        // Don't duplicate entries
        if (!res[field].find((item) => item.value === data.value))
          res[field].push(data);
      }
    }
  }

  return res;
}

// getGenericMap builds a map with the id as key and the struct
// as value. It filters the entries based on the visible sheets
// of the provided role.
// Only Status and Sheets are supported
export async function getGenericMap<T>(
  role: IRole,
  section: AdminSection
): Promise<GenericMap<T>> {
  const api = apiServices[section];
  const records = (await api.getAll()) as T[];
  const idField = (
    section === AdminSection.Sheets ? SheetColumns.ID : StatusColumns.ID
  ) as keyof T;
  // create a sheets map based keep only the visible statuses for the user role
  const res: GenericMap<T> = {};
  for (const s of records) {
    if (
      role.roleId != ADMIN_ROLE_ID &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !role.visibleSheets.includes(s.sheetId)
    ) {
      continue;
    }
    const key = s[idField] as number;
    res[key] = s;
  }
  return res;
}

export function pageIsOutOfBounds(
  cachedPage: number,
  totalRows: number,
  rowsPerPage: number
): boolean {
  if (!totalRows) return false;
  // Calculate the maximum page value based on total rows and rows per page
  const maxPage: number = Math.floor((totalRows - 1) / rowsPerPage);
  // Check if the cached page is out of bounds
  if (cachedPage < 0 || cachedPage > maxPage) {
    return true;
  }
  return false;
}

// getStatusOptions is a helper function used for the status
// form field options. It gets the status options
// based on the specified status and its possible derivations.
export function getStatusOptions(
  sheetsMap: GenericMap<ISheet>,
  status: IStatus
): IOption[] {
  const derivations: IStatus[] = [];
  for (const sheet of Object.values(sheetsMap)) {
    if (!sheet.statuses) {
      continue;
    }
    for (const s of sheet.statuses) {
      if (!status.derivations.includes(s.statusId)) {
        continue;
      }
      derivations.push(s);
    }
  }

  const options = derivations.map((s: IStatus) => ({
    value: s.statusId,
    label: s.name.toUpperCase(),
  }));

  // first option should be current status (the selected by default)
  status &&
    options.unshift({
      value: status.statusId,
      label: status.name.toUpperCase(),
    });
  return options;
}

// getProdOptions is a helper function used for the producto
// form field options. It gets the productos options
export function getProdOptions(productos: IProducto[]): IOption[] {
  return productos.map((p) => ({ value: p.productoId, label: p.nombre }));
}
