import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import DescriptionIcon from "@mui/icons-material/Description";
import { lang } from "../../lang";

export interface ListItem {
  title: string;
  onClick: () => void;
  img?: React.JSX.Element;
  cols?: number;
}

const itemStyle = {
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#9abdb8",
  },
};

export const iconStyle = {
  fontSize: 100,
  color: "rgba(255, 255, 255, 0.54)",
};

interface Props {
  title: string;
  items: ListItem[];
}

export default function TitlebarImageList(props: Props) {
  const itemCount = props.items.length;
  let row = 0;
  // keep track of the slots taken on each
  // column to avoid having a void in the last row
  let slotsOccupied = 0;
  return (
    <ImageList sx={{ maxWidth: 500, height: "100%", margin: "auto" }}>
      <ImageListItem key="Subheader" cols={2}>
        <ListSubheader style={{ fontSize: "1.5rem" }} component="div">
          {lang(props.title)}
        </ListSubheader>
      </ImageListItem>
      {props.items.map((item, index) => {
        slotsOccupied += item.cols || 1;
        const mod = index % 2;
        const color =
          (mod === 0 && row % 2 == 0) || (mod !== 0 && row % 2 !== 0)
            ? "#cfc6c1"
            : "#c0c0c0";
        mod === 1 && row++;
        // if there's an odd number of items, the last item will span the
        // 2 columns so is not showing a void
        if (index == itemCount - 1 && slotsOccupied % 2 == 1 && !item.cols) {
          item.cols = 2;
        }
        return (
          <ImageListItem
            key={item.title}
            cols={item.cols}
            onClick={item.onClick}
            sx={{
              ...itemStyle,
              backgroundColor: color,
            }}
          >
            {item.img || <DescriptionIcon sx={iconStyle} />}
            <ImageListItemBar title={lang(item.title)} />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}
