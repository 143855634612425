import React from "react";
import { FormControlLabel } from "@material-ui/core";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { formStyles } from ".";
import { formatColumnName } from "../../utils/formatters";
import { AdminEntity } from "../../types";

const CheckboxListEntity = (props: {
  formik: any;
  label: string;
  field: string;
  options: AdminEntity[];
  idCol: string;
  disabled?: boolean;
}) => {
  const useStyles = formStyles;
  const classes = useStyles();
  // the formik values can be just an array of ids
  // or an array of the entity. In latter case, need to create an array of ids
  // to mark the selected as checked
  const initialValues =
    props.formik.values[props.field].length &&
    props.formik.values[props.field][0][props.idCol]
      ? props.formik.values[props.field].map((v: any) => v[props.idCol])
      : props.formik.values[props.field];

  return (
    <FormControl
      key={"fc-status-1"}
      variant="outlined"
      className={classes.formControl}
      error={
        props.formik.touched[props.field] &&
        Boolean(props.formik.errors[props.field])
      }
    >
      <Typography
        variant="h6"
        gutterBottom
        color="textSecondary"
        style={{ marginTop: "2rem" }}
      >
        {props.label}
      </Typography>
      <FormGroup>
        {props.options.map((o: any) => (
          <FormControlLabel
            key={`chk-${o.name}-${o[props.idCol]}`}
            control={
              <Checkbox
                checked={initialValues.includes(o[props.idCol])}
                disabled={props.disabled}
                onChange={(event) => {
                  const checked = event.target.checked;
                  props.formik.setFieldValue(
                    props.field,
                    checked
                      ? [...initialValues, o[props.idCol]]
                      : initialValues.filter(
                          (id: number) => id !== o[props.idCol]
                        )
                  );
                }}
                value={o[props.idCol]}
              />
            }
            label={formatColumnName(o.name)}
          />
        ))}
      </FormGroup>
      {props.formik.touched[props.field] &&
        props.formik.errors[props.field] && (
          <FormHelperText>{props.formik.errors[props.field]}</FormHelperText>
        )}
    </FormControl>
  );
};

export default CheckboxListEntity;
