import { useState, useEffect } from "react";
import { Order, SortingField } from "../types";

// store user preferences to have better ux
function useSortingStorage(defaultOrder: Order, defaultOrderBy: SortingField) {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  useEffect(() => {
    const storedOrder = localStorage.getItem("sortingOrder");
    const storedOrderBy = localStorage.getItem("sortingOrderBy");

    if (storedOrder) {
      setOrder(storedOrder as Order);
    }
    if (storedOrderBy) {
      setOrderBy(storedOrderBy as SortingField);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("sortingOrder", order);
    localStorage.setItem("sortingOrderBy", orderBy);
  }, [order, orderBy]);

  return { order, setOrder, orderBy, setOrderBy };
}

export default useSortingStorage;
