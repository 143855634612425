import { AdminSection, GenericMap, IRole, ISheet, IStatus } from "../../types";
import rolesApi from "../../services/api/roles";
import { getGenericMap } from "../../utils";

export const storeMap = async <T>(
  section: AdminSection,
  role: IRole
): Promise<GenericMap<T>> => {
  const res = await getGenericMap<T>(role, section);
  localStorage.setItem(section, JSON.stringify(res));
  return res;
};

export const getStoredItem = <T>(
  section: AdminSection | "role" | "user"
): GenericMap<T> | T[] | T | undefined => {
  const storedStr = localStorage.getItem(section);
  return storedStr && JSON.parse(storedStr as string);
};

export const refreshStoredState = async (
  section: AdminSection,
  role?: IRole
): Promise<void> => {
  if (!role) {
    role = getStoredItem("role") as IRole;
  }
  // after create/edit a role,
  // refresh the roles saves in local storage
  if (section === AdminSection.Roles) {
    const rs = await rolesApi.getAll();
    localStorage.setItem(AdminSection.Roles, JSON.stringify(rs));
  }
  // after create/edit a status or sheet
  // update local storage sheets map item
  if (section === AdminSection.Status || section === AdminSection.Sheets) {
    await storeMap<ISheet>(AdminSection.Sheets, role);
    await storeMap<IStatus>(AdminSection.Status, role);
  }
};
